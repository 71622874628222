import { render, staticRenderFns } from "./post.vue?vue&type=template&id=1237e090"
import script from "./post.vue?vue&type=script&lang=js"
export * from "./post.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogCategories: require('/vercel/path0/components/blogCategories.vue').default,Block: require('/vercel/path0/components/ui/block.vue').default,BlogPost: require('/vercel/path0/components/blogPost.vue').default})
