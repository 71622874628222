
import { mapState } from 'vuex';
import { hydrateWhenIdle, hydrateWhenVisible } from 'nuxt-lazy-hydration';

export default {
  name: 'Block',

  components: {
    cards: hydrateWhenVisible(() => import(`@/components/blocks/cards.vue`)),
    contentPanel: hydrateWhenVisible(() =>
      import(`@/components/blocks/contentPanel.vue`)
    ),
    ctaSmall: hydrateWhenVisible(() =>
      import(`@/components/blocks/ctaSmall.vue`)
    ),
    customBlock: hydrateWhenVisible(() =>
      import(`@/components/blocks/customBlock.vue`)
    ),
    faq: hydrateWhenVisible(() => import(`@/components/blocks/faq.vue`)),
    gallery: hydrateWhenVisible(() =>
      import(`@/components/blocks/gallery.vue`)
    ),
    hero: hydrateWhenIdle(() => import(`@/components/blocks/hero.vue`)),
    heroAlt: hydrateWhenIdle(() => import(`@/components/blocks/heroAlt.vue`)),
    links: hydrateWhenVisible(() => import(`@/components/blocks/links.vue`)),
    list: hydrateWhenVisible(() => import(`@/components/blocks/list.vue`)),
    news: hydrateWhenVisible(() => import(`@/components/blocks/news.vue`)),
    newsletter: hydrateWhenVisible(() =>
      import(`@/components/blocks/newsletter.vue`)
    ),
    productSlider: hydrateWhenVisible(() =>
      import(`@/components/blocks/productSlider.vue`)
    ),
    promo: hydrateWhenVisible(() => import(`@/components/blocks/promo.vue`)),
    quoteBlock: hydrateWhenVisible(() =>
      import(`@/components/blocks/quoteBlock.vue`)
    ),
    slider: hydrateWhenVisible(() => import(`@/components/blocks/slider.vue`)),
    testimonials: hydrateWhenVisible(() =>
      import(`@/components/blocks/testimonials.vue`)
    ),
    videoBlock: hydrateWhenVisible(() =>
      import(`@/components/blocks/videoBlock.vue`)
    ),
  },

  props: {
    block: {
      type: Object,
      required: true,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState(['options']),

    component() {
      return this.block.acf_fc_layout || [];
    },

    className() {
      return `block_${this.block.acf_fc_layout}`;
    },

    sharedblocks() {
      return this.options.blocks;
    },

    blockData() {
      if (this.block.content === 'shared') {
        const filtered = this.sharedblocks.filter(
          (b) => b.acf_fc_layout === this.block.acf_fc_layout
        );
        return filtered.length > this.block.content_option
          ? filtered[this.block.content_option]
          : this.block;
      }
      return this.block;
    },
  },
};
