
import WpImage from '~/components/ui/img.vue';

export default {
  name: 'BlogPost',

  components: {
    WpImage,
  },

  props: {
    post: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
};
