
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'BlogCategories',

  props: {
    tags: {
      type: Array,
      default: null,
    },
    isPostTags: {
      type: Boolean,
      default: false,
    },
  },

  async fetch() {
    if (!this.blogCategories?.length > 0) {
      const { data } = await this.$wp.categories();
      this.setBlogCategories(data);
    }
  },

  computed: {
    ...mapState(['blogCategories']),

    categories() {
      return !this.tags
        ? this.blogCategories.filter((c) => c.name !== 'Uncategorized')
        : this.blogCategories.filter((c) => this.tags.includes(c.id));
    },
  },

  methods: {
    ...mapMutations(['setBlogCategories']),
  },
};
