
import Block from '@/components/ui/block.vue';
import BlogCategories from '@/components/blogCategories.vue';
import WpImage from '~/components/ui/img.vue';

export default {
  name: 'Post',

  components: {
    Block,
    WpImage,
    BlogCategories,
  },

  props: {
    post: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      relatedPosts: null,
    };
  },

  computed: {
    defaultBlock() {
      return this.post.acf.default_blocks
        ? this.post.acf.default_blocks[0]
        : null;
    },
    relatedPostsFiltered() {
      return this.relatedPosts
        ? this.relatedPosts.filter((p) => p.id !== this.post.id)
        : null;
    },
    excerpt() {
      return this.post
        ? this.post.excerpt.rendered.replace(/(?:\r\n|\r|\n)/g, '<br>')
        : '';
    },
  },

  async mounted() {
    const { data } = await this.$wp.posts({
      perPage: 6,
      categories: this.post.categories[0],
    });
    if (data) {
      this.relatedPosts = data;
    }
  },
};
